import { useEffect, useMemo } from 'react';
import type { MangoQuery } from 'rxdb';

import { DEFAULT_LIMIT_BUFFER, DEFAULT_MANGO_QUERY_LIMIT } from '../../constants.platform';
import { type AnyDocument, FilteredView, FirstClassDocument, SplitByKey } from '../../types';
import { useFind } from '../databaseHooks';
import { useDisablePersistentQueryCache } from '../models';
import useFilteredViewMangoQuery from '../useFilteredViewMangoQuery';
import getSplitByDefaultValue from './getSplitByDefaultValue';
import getUIFriendlyNameForDocumentLocation from './getUIFriendlyNameForDocumentLocation';
import useDocumentLocations from './useDocumentLocations';
import useLiveValueRef from './useLiveValueRef';

type Result = {
  areThereMoreDocumentsNotFetched: boolean;
  documents: FirstClassDocument[];
  extraMessage?: {
    category: 'rowEmptyMessage' | 'rowErrorMessage';
    text: string;
  };
  isFetching: boolean;
  parserErrorMessage?: string;
};

export default function useViewRowData({
  isActive = true,
  maxNumberOfDocumentsPerRow,
  onQueryEnded,
  onQueryError,
  view,
}: {
  isActive?: boolean;
  maxNumberOfDocumentsPerRow: number;
  onQueryEnded?: () => void;
  onQueryError?: () => void;
  view: FilteredView;
}): Result {
  const documentLocations = useDocumentLocations();

  const {
    mangoQuery: mangoQueryWithoutLimit,
    databaseHookOptions,
    parserErrorMessage,
  } = useFilteredViewMangoQuery({
    view,
    splitValue: view.splitBy
      ? (getSplitByDefaultValue(view.splitBy, documentLocations) ?? undefined)
      : undefined,
  });

  // Add default limit to make query same as in filter view, to ensure the same cached query results are used.
  const mangoQuery: MangoQuery<AnyDocument> | undefined = useMemo(
    () =>
      mangoQueryWithoutLimit && {
        ...mangoQueryWithoutLimit,
        limit: DEFAULT_MANGO_QUERY_LIMIT,
      },
    [mangoQueryWithoutLimit],
  );

  const isQueryEnabled = useMemo(() => isActive && !parserErrorMessage, [isActive, parserErrorMessage]);
  const disablePersistentQueryCache = useDisablePersistentQueryCache();

  const [allDocumentsFetched, { isFetching }] = useFind<'documents', FirstClassDocument>(
    'documents',
    mangoQuery,
    {
      isEnabled: isQueryEnabled,
      // Use the same limit buffer size as the list view to ensure query cache hits
      limitBuffer: DEFAULT_LIMIT_BUFFER,
      // showInUnseenAfter queries can never be cached since they're different every session,
      // so don't bother persisting them
      enablePersistentQueryCache:
        !disablePersistentQueryCache &&
        !JSON.stringify(mangoQuery).includes('showInUnseenAfter') &&
        !JSON.stringify(mangoQuery).includes('showInSeenAfter'),
      ...databaseHookOptions,
    },
  );

  useEffect(() => {
    if (parserErrorMessage) {
      onQueryError?.();
    }
  }, [parserErrorMessage, onQueryError]);

  const onQueryEndedRef = useLiveValueRef(onQueryEnded);

  useEffect(() => {
    if (!isFetching && isQueryEnabled) {
      onQueryEndedRef.current?.();
    }
  }, [isFetching, onQueryEndedRef, isQueryEnabled]);

  let extraMessage: Result['extraMessage'];
  if (!isFetching && !allDocumentsFetched.length) {
    let extraMessageText: string;
    if (view.splitBy === SplitByKey.DocumentLocation) {
      extraMessageText = `No documents in ${getUIFriendlyNameForDocumentLocation(documentLocations[0])}`;
    } else if (view.splitBy === SplitByKey.Seen) {
      extraMessageText = 'No unseen documents';
    } else {
      extraMessageText = 'No documents!';
    }

    extraMessage = {
      category: 'rowEmptyMessage',
      text: extraMessageText,
    };
  } else if (parserErrorMessage) {
    extraMessage = {
      category: 'rowErrorMessage',
      text: parserErrorMessage,
    };
  }

  return {
    areThereMoreDocumentsNotFetched: allDocumentsFetched.length > maxNumberOfDocumentsPerRow,
    documents: allDocumentsFetched.slice(0, maxNumberOfDocumentsPerRow),
    extraMessage,
    isFetching,
    parserErrorMessage,
  };
}
